h1 {
  font-size: 32pt !important;
  padding-top: 0px !important;
}

h2 {
  font-size: 26pt !important;
  padding-top: 0px !important;
}

h3 {
  font-size: 22pt !important;
  padding-top: 0px !important;
}

h4 {
  font-size: 20pt !important;
  padding-top: 0px !important;
}

h5 {
  font-size: 18pt !important;
  padding-top: 0px !important;
}

h6 {
  font-size: 16pt !important;
  padding-top: 0px !important;
}
