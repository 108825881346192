// .custom-category-background {
//     background-color: #1f1f1f;
//     background-image: url("../../assets/categories/what_you_want.svg");
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: left;
//   }
.custom-category-background {
  height: 100%;
  width: 100%;
  background-image: url("../../assets/categories/what_you_want.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
}

  /* // Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
  }
  
  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }
  
  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .custom-category-background {
      height: 100%;
      width: 100%;
      background-image: url("../../assets/categories/models3d_custom.webp");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: grid;
      place-items: center;
  }
  }
  
  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
  
  /* // Extra extral large devices (large desktops, 1200px and up) */
  @media (min-width: 1400px) {
  }
  