#notifications-desktop::-webkit-scrollbar {
  width: 5px;
  /* height: 64px; */
}

#notifications-desktop::-webkit-scrollbar-track {
  margin-top: 250px;
  margin-bottom: 250px;
}

#notifications-desktop::-webkit-scrollbar-track-piece {
  background-color: #fde8ce;
  border-radius: 5px;
  /* width: 4px; */
  border: 10px solid transparent;
}

#notifications-desktop::-webkit-scrollbar-thumb {
  background-color: #f7941d;
  outline: 0px solid #f7941d;
  border-radius: 3px;
  
}
