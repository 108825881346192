.item-details-card {
  min-height: 200px;
  min-width: 750px;
  border-radius: 20px !important;
}

.cartImgLeft {
  position: absolute;
  left: -70px;
  top: 39px;
}

.favImgLeft {
  position: absolute;
  left: -70px;
  top: 35px;
}

.cartPriceRight {
  right: 0px;
  bottom: 0px;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.removeButtonRight {
  right: 7px;
  top: 7px;
}

.refund-modal-backdrop {
  background-color: #000 !important;
  opacity: 1;
}

.removeFavouriteButtonRight {
  right: -10px;
  top: -10px;
}

.order-card {
  min-height: 200px;
  border-radius: 15px !important;
  background-color: #141415 !important;
  border: 1px solid #3D3D3D !important;
}

.coupon-applied {
  display: table;
  margin: 0px 0px 0px 0px;
  padding: 4px 12px;
  border-left: 6px solid green;
  background-color: rgb(223, 247, 223);
  color: green;
  text-decoration: bold;
}

.coupon-applied-text {
  font-weight: bold;
}
