.react-date-picker{
    background-color: #282929;
    border: none !important;
    min-Width: 250px;
}

.react-date-picker__wrapper{
    height: 45px;
    padding: 10px;
}

.react-date-picker__button__icon{
    stroke: white;
    size: 20px;
}

.react-date-picker__inputGroup__input{
    color: white;
}

.react-date-picker__calendar{
    color: black;
}