.navbar-background {
  background-color: #1B1C1D
;
}

.main-nav-links:hover {
  text-decoration: none;
}

.border-underline {
  height: 1px;
  width: 100%;
  border: 1px solid #ed1a3b !important;
  border-radius: 100px !important;
}

.categories-container {
  width: 450px !important;
  border: 2px solid #bebebe;
}

.more-container {
  border: 2px solid #bebebe;
}

.login-modal-backdrop {
  background-color: #000 !important;
  opacity: 1;
}

.dropdown-toggle::after {
  color: #f7941d;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .navbar-background {
    background-color: #1b1c1d;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // Extra extral large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
