/* // Common CSS */

.input-box {
  border: none !important;
  color: #fff !important;
  background: none !important;
  border-bottom: 2px solid #ccc !important;
  border-radius: 0px !important;
}

.input-box-colored {
  border: none !important;
  border-bottom: 2px solid #ccc !important;
  background-color: #06575b !important;
}

.input-box:focus {
  border-bottom: 2px solid #ccc !important;
  box-shadow: none !important;
}

.textarea-input-box {
  border: none !important;
}

.text-mobile {
  font-size: 14px;
}

.contactform-mobile-bg {
  width: 228px;
}

.formbox {
  background: none;
}

@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .title {
    font-size: 50px;
    font-weight: 600;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 400;
  }

  .social-icons {
    width: 30px;
    height: 30px;
  }

  .objectimage {
    width: 472px;
  }

  .formbox {
    box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.15);
  }

  .form-floating label,
  .label {
    font-size: 14px !important;
    font-family: "Poppins";
  }

  .form-floating {
    margin-bottom: 10px;
  }

  .textarea-container {
    margin-top: 20px;
  }

  .bottom-text {
    font-size: 14px;
    font-weight: 400;
  }

  .contact-icons {
    width: 24px;
    height: 24px;
  }
}

/* // Extra Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .title {
    font-size: 70px;
    font-weight: 600;
  }

  .subtitle {
    font-size: 24px;
    font-weight: 400;
  }

  .social-icons {
    width: 40px;
    height: 40px;
  }

  .objectimage {
    width: 662px;
  }

  .form-floating label,
  .label {
    font-size: 16px !important;
    font-family: "Poppins";
  }

  .textarea-container {
    margin-top: 20px;
  }

  .bottom-text {
    font-weight: 18px;
    font-weight: 400;
  }

  .form-floating {
    margin: 15px 0px;
  }

  .formbox {
    margin: 35px 0px;
  }

  .contact-icons {
    width: 24px;
    height: 24px;
  }
}
