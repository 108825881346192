.centerModeReviewCard {
  width: 50px;
  height: 50px;
}

// .reviewCard {
//   margin-top: 60px;
//   padding-top: 30px;
// }

.cardImgTop {
  position: absolute;
  top: -70px;
  width: 120px;
  height: 120px;
}

.underlinePosition {
  position: absolute;
  top: 30%;
  left: 30%;
}

.businessSliderImage {
  width: 200px;
}

.reviewCard {
  width: 280px;
  height: 150px;
}

.caseStudyCard {
  width: 300px;
}

.iframe-preview {
  cursor:default;
  
  &:hover .iframe-hover {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}

// width: 375, height: 150,

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .businessSliderImage {
    width: 375px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .businessSliderImage {
    width: 500px;
  }

  .caseStudyCard {
    width: 100%;
  }

  .reviewCard {
    width: 355px;
    height: 150px;
  }
  .reviewCardActive {
    // width: 355px;
    height: 200px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .businessSliderImage {
    width: 550px;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .businessSliderImage {
    width: 750px;
  }
}

/* // Extra extral large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .businessSliderImage {
    width: 900px;
  }
}
