:focus {
  box-shadow: none !important;
}

.explore-button-large-no-neo {
  // padding: 24px 40px !important;
  padding: 14px 40px !important;
  border-radius: 4px;
  color: white !important;
  font-size: 14px;
}

.explore-button-large-no-neo-round {
  // padding: 24px 40px !important;
  padding: 14px 40px !important;
  border-radius: 30px;
  color: white !important;
}

.explore-button-no-neo {
  text-decoration: none !important;
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 14px;
  // line-height: 19.6px;
  // letter-spacing: 1.25px;
  // padding: 24px 40px;
  border-radius: 5px;
  border: 1px solid #1f1f1f;
  background: none;
  background-image: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
  padding: 14px 40px!important;
  // border-radius: 4px !important;
  color: white !important;

}

.explore-border-button-no-neo {
  position: relative;
}


.btn-primary:hover{
  background-color: #292929;
  border-color: #292929;
  color: #FFFFFF;
}
.btn-primary:active{
  background-color: #292929;
  border-color: #292929;
  color: #FFFFFF;
}

.btn-primary:focus{
  background-color: #292929;
  border-color: #292929;
  color: #FFFFFF;

}
.explore-border-button-no-neo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px; /*1*/
  border: 2px solid transparent; /*2*/
  background: linear-gradient(to right, #ed1a3b 7.74%, #511aed 100%) border-box; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}

.explore-border-button-no-neo.btn-dark {
  background-color: #292929 !important;
  padding: 14px 40px !important;
  border-radius: 4px !important;
  color: white !important;
  
}

.explore-gray-border-button-no-neo {
  position: relative;
}

.explore-gray-border-button-no-neo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px; /*1*/
  border: 1px solid #3d3d3d;
}

.explore-gray-border-button-no-neo.btn-dark {
  background-color: #292929 !important;
  padding: 14px 40px !important;
  border-radius: 4px !important;
  color: white !important;
  font-weight: 500;
  font-size: 14px;
}

.btn-dark.btn-znanye-menu {
  background-color: #292929;
  padding: 0px !important;
  width: 64px;
  height: 32px;
  border: 1px solid #292929;
}

.btn-dark.btn-znanye-search {
  background-color: #292929;
  padding: 0px !important;
  width: 32px;
  height: 32px;
  border: 1px solid #292929;
}

.btn-dark.btn-znanye-search-lg {
  background-color: #292929;
  padding: 0px !important;
  width: 48px;
  height: 48px;
  border: 1px solid #292929;
  border-radius: 10px !important;
}

.btn-dark.btn-znanye-cart {
  background-color: #292929;
  padding: 0px !important;
  width: 32px;
  height: 32px;
  border: 1px solid #292929;
}

.title-text-seller-login {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 275;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #ffffff;
}

.br-10 {
  border-radius: 10px !important;
}

.seller-login-inputs {
  background-color: #0d0d0d;
  border-color: #3d3d3d;
  height: 48px;
}

.seller-login-inputs::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  letter-spacing: 0.25px;

  /* Disable & boarder */

  color: #3d3d3d;
}

.seller-login-inputs:focus{
  background-color: #0d0d0d;
  // border-color: #e1e1e1;
}

.fs-10px {
  font-size: 10px;
}
