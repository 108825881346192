/* // Common CSS */

.input-box {
  border: none !important;
  border-bottom: 2px solid #ccc !important;
}

.input-box:focus {
  border-bottom: 2px solid #ccc !important;
  box-shadow: none !important;
}

.footer-headers {
  font-weight: 600;
  font-size: 16px;
}

.footer-info {
  font-weight: 400;
  font-size: 14px;
}

.footer-link {
  font-family: "Poppins";
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.footer-strip {
  background-color: #141414;
}

@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .footer-headers {
    font-weight: 600;
    font-size: 16px;
  }

  .footer-info {
    font-weight: 400;
    font-size: 14px;
  }

  .footer-link {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
  }
}

/* // Extra Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1900px) {
  .footer-info {
    font-weight: 400;
    font-size: 14px;
  }

  .footer-link {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
  }
}
