// .item-details-card {
//   max-height: 50px;
//   max-width: 50px;
//   border-radius: 20px !important;
// }

.cartImgLeft {
  position: absolute;
  left: -70px;
  top: 39px;
}

.favImgLeft {
  position: absolute;
  left: -70px;
  top: 35px;
}

.cartPriceRight {
  right: 0px;
  bottom: 0px;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.removeButtonRight {
  right: 7px;
  top: 7px;
}

.reviewCloseButtonRight {
  right: 25px;
  top: 25px;
}

.removeFavouriteButtonRight {
  right: -10px;
  top: -10px;
}

.purchased-item-card {
  min-height: 200px;
  min-width: 750px;
  border-radius: 0px !important;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .item-details-card {
    min-height: 200px;
    min-width: 550px;
    border-radius: 20px !important;
  }

  .cartImgLeft {
    position: absolute;
    left: -70px;
    top: 39px;
  }

  .favImgLeft {
    position: absolute;
    left: -70px;
    top: 35px;
  }

  .cartPriceRight {
    right: 0px;
    bottom: 0px;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
  }

  .removeButtonRight {
    right: 7px;
    top: 7px;
  }

  .reviewCloseButtonRight {
    right: 25px;
    top: 25px;
  }

  .removeFavouriteButtonRight {
    right: -10px;
    top: -10px;
  }

  .purchased-item-card {
    min-height: 200px;
    min-width: 750px;
    border-radius: 0px !important;
  }
}

/* // Extra extral large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
