.slider-icon-button {
  border-radius: 30px !important;
  width: 35px;
  height: 35px;
}

.business-home-we {
  background-image: url("./../../assets/business/who-are-we-mobile.webp");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 60vh;
}

.business-background-case {
  background-image: url("../../assets/business/case.webp");
  background-repeat: no-repeat;
  background-position: 5% 1%;
  background-size: 90px;
  background-color: #1f1f1f;
}

.business-background-process {
  background-image: url("../../assets/business/process.webp");
  background-repeat: no-repeat;
  // background-position: 20% 10%;
  background-size: 300px;
  background-position: top 80px right -30px;
  background-color: #1f1f1f;
}

.business-home-telephone {
  background-image: url("./../../assets/business/telephone-mobile.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.processDescription {
  width: 300px;
  height: 65px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .business-home-guy-vr {
    background-image: url("./../../assets/business/guy-vr.webp");
    background-repeat: no-repeat;
    background-size: 55%;
    background-position: 100% 20%;
    min-height: 90vh;
  }

  .slider-icon-button {
    border-radius: 40px !important;
    width: 45px;
    height: 45px;
  }

  .business-home-we {
    background-image: url("./../../assets/business/who-are-we.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
  }

  .business-background-case {
    background-image: url("../../assets/business/case.webp");
    background-repeat: no-repeat;
    background-position: 9% 102%;
    background-size: 200px;
    background-color: #1f1f1f;
    min-height: 100vh;
  }

  .business-background-process {
    background-image: url("../../assets/business/process.webp");
    background-repeat: no-repeat;
    background-size: 850px;
    background-position: top 20px right;
    background-color: #1f1f1f;
    min-height: 90vh;
  }

  .casestudy-background-case {
    background-color: #1f1f1f;
    min-height: 40vh;
  }

  .business-home-telephone {
    background-image: url("./../../assets/business/telephone.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .processDescription {
    width: 520px;
    height: 65px;
  }

  .business-sell-viewer {
    background-color: #1f1f1f;
    min-height: 90vh;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // Extra extral large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
