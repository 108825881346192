$primary: #f7941d;
$secondary: #ed1a3b;
$info: #828282;
$breadcrumb-divider: ">";

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "info": $info,
  "black-gray": #313335,
);
@import "node_modules/bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "ui-neumorphism/dist/index.css";

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import "currency-flags/dist/currency-flags.css";


input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.custom-popover.popover {
  overflow: visible !important;
  width: 180px !important;
  height: 210px !important;
  z-index: 99;
  position: absolute ! important;
}

.custom-popover {
  z-index: 9999;
}

.open {
  display: block;
}

body {
  font-family: "Poppins" !important;
}

.poppins {
  font-family: "Poppins" !important;
}

.poppins-200 {
  font-family: "Poppins";
  font-weight: 200;
  font-style: normal;
}

.poppins-200-i {
  font-family: "Poppins";
  font-weight: 200;
  font-style: italic;
}

.poppins-300 {
  font-family: "Poppins";
  font-weight: 300;
  font-style: normal;
}

.poppins-300-i {
  font-family: "Poppins";
  font-weight: 300;
  font-style: italic;
}

.poppins-400 {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
}

.poppins-400-i {
  font-family: "Poppins";
  font-weight: 400;
  font-style: italic;
}

.poppins-500 {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
}

.poppins-500-i {
  font-family: "Poppins";
  font-weight: 500;
  font-style: italic;
}

.poppins-600 {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
}

.poppins-600-i {
  font-family: "Poppins";
  font-weight: 600;
  font-style: italic;
}

.poppins-700 {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
}

.poppins-700-i {
  font-family: "Poppins";
  font-weight: 700;
  font-style: italic;
}

.poppins-800 {
  font-family: "Poppins";
  font-weight: 800;
  font-style: normal;
}

.poppins-800-i {
  font-family: "Poppins";
  font-weight: 800;
  font-style: italic;
}

.poppins-900 {
  font-family: "Poppins";
  font-weight: 900;
  font-style: normal;
}

.poppins-900-i {
  font-family: "Poppins";
  font-weight: 900;
  font-style: italic;
}

h1,
.h1 {
  font-size: 40px !important;
}

h2,
.h2 {
  font-size: 24px !important;
}

h3,
.h3 {
  font-size: 20px !important;
}

h4,
.h4 {
  font-size: 16px !important;
}

.earn-by-selling {
  font-size: 48px !important;
}

.font-size32 {
  font-size: 32px;
}

.font-size18 {
  font-size: 18px;
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.body-text1 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.body-text2 {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.body-text3 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.caption {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.overline {
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.5px;
}

.small-title {
  font-weight: 500;
  font-size: 10.5px;
}

// focus outline
.outline-none:focus,
.outline-none:active {
  outline: none !important;
  box-shadow: none !important;
}

// focus outline
.outline-text-none:focus,
.outline-text-none:active {
  outline: none !important;
  box-shadow: 4px 4px 10px #111, -5px -5px 10px #555;
}

// Buttons

.znanye-button {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 14px 40px;
  border-radius: 4px;
}

.znanye-button-large {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 20px 118px;
  border-radius: 4px;
}

.rotate-on-hover {
  animation: rotateY 0.8s ease-in-out 1;
}

.rotate-on-hover-negative {
  animation: rotateY-negative 0.8s ease-in-out 1s;
}

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}

@keyframes rotateY-negative {
  0% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

.rotate-behind {
  transform: rotate3d(0, 1, 0, 90deg);

}


.light-animation {
  animation: shrinkAndBrighten 0.5s linear 1;
}

@keyframes shrinkAndBrighten {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.znanye-icon-button {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 14px 14px;
  border-radius: 4px;
}

.explore-icon-button {
  text-decoration: none !important;
  font-family: "Poppins" !important;
  font-weight: 500;
  border-radius: 10px;
  background: none;
  background-image: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
  width: 48px !important;
  height: 48px !important;
  min-width: 48px !important;
}

.explore-icon-profile-button {
  text-decoration: none !important;
  font-family: "Poppins" !important;
  color: #ffffff;
  font-weight: 500;
  border-radius: 10px;
  background: none;
  // background-image: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
  background-color: #1F1F1F;
  width: 48px;
  height: 48px;
  // min-width: 48px;
  border-width: 1px;
  border-style: solid !important;

  // border-image-source: linear-gradient(93.69deg, #ED1A3B 7.74%, #511AED 100%)!important;


}


.explore-slider-icon {
  background-image: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
}

.explore-button {
  text-decoration: none !important;
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: 1.25px;
  padding: 16px 40px;
  border-radius: 4px;
  border: 1px solid #1f1f1f;
  background: none;
  background-image: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
}

.explore-button-large {
  padding: 24px 40px !important;
}

.explore-button-cart {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 14px 40px;
  border-radius: 4px;
  background: none;
  background-image: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
}

.explore-button-order {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 12px 30px;
  border-radius: 4px;
  border: 1px solid #1f1f1f;
  background: none;
  background-image: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
}

.white-button {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 14px 40px;
  border-radius: 4px;
  background: white;
}

.grey-button {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 14px 40px;
  border: 1px solid #3d3d3d;
  border-radius: 4px;
  background-color: #292929 !important;
  outline: none;
  box-shadow: none !important;
}

.explore-text {
  background: -webkit-linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.explore-background {
  background: -webkit-linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
}

.explore-border-button {
  position: relative;
}

.explore-border-button-round {
  position: relative;
}

.explore-border-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  /*1*/
  border: 2px solid transparent;
  /*2*/
  background: linear-gradient(to right, #ed1a3b 7.74%, #511aed 100%) border-box;
  /*3*/
  -webkit-mask:
    /*4*/
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  /*5'*/
  mask-composite: exclude;
  /*5*/
}

.explore-border-button-round::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  /*1*/
  border: 2px solid transparent;
  /*2*/
  background: linear-gradient(to right, #ed1a3b 7.74%, #511aed 100%) border-box;
  /*3*/
  -webkit-mask:
    /*4*/
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  /*5'*/
  mask-composite: exclude;
  /*5*/
}

ul.breadcrumb li+li:before {
  padding-top: 2px;
}

.border-R20 {
  border-radius: 20px !important;
}

.border-R8 {
  border-radius: 8px !important;
}

.border-R10 {
  border-radius: 10px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-shadow {
  text-shadow: -4px -4px 10px #555;
}

.text-decoration-none {
  text-decoration: none !important;
}

.custom-card {
  box-shadow: none !important;
  border: 1px solid #3d3d3d !important;
}

.custom-card-profile {
  box-shadow: none !important;
  // border: 1px solid #3d3d3d !important; 
}

.css-mxks5r-Menu {
  border-radius: 20px !important;
}

.text-justify {
  text-align: justify;
}

.line-below-heading {
  color: #999999;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #222;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(73, 72, 72);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(73, 72, 72);
}

.noScrollBar::-webkit-scrollbar {
  scrollbar-width: 4px;
  /* for Chrome, Safari, and Opera */
}

.noScrollBar {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.customNeumo {
  box-shadow: 4px 4px 10px #222, -5px -5px 10px #555;
}

.bs-customNeumo {
  outline: none;
  box-shadow: 4px 4px 10px #222, -5px -5px 10px #555;
}

.bs-customNeumo:hover {
  border: none;
  outline: none;
  box-shadow: 4px 4px 10px #222, -5px -5px 10px #555;
}

.customNeumo-textinput {
  box-shadow: 4px 4px 10px #111, -5px -5px 10px #444;
}

.border-radius-zero {
  border-radius: 0px !important;
}

.padding-zero {
  padding: 0px !important;
}

.my-button-hover:hover {
  border: none !important;
  border-color: none !important;
}

/* Style the list */
ul.breadcrumb {
  list-style: none;
  font-family: "Poppins";
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  color: #999;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
  padding: 4px;
  color: #fff;
  content: ">";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #fff;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #ccc;
  text-decoration: none;
}

.wrapped-button:focus {
  outline: none;
  box-shadow: none !important;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-30 {
  width: 30%;
}

.case-study-box {
  width: 100%;
}

.iframe-preview {
  cursor: default;

  &:hover .iframe-hover {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}

.loginMobileMain {
  position: relative;
  width: 310px;
  height: 110px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  overflow: hidden;
}

.loginMobileFill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
}

.bank-type-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-position: calc(100% - 12px) center !important;
  background-color: #0c0d0c !important;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  border: 1px solid #3d3d3d;
  border-radius: 10px;
  padding: 10px;
}

.w-dialog {
  max-width: "100%";
}

.w-contentBox {
  border-radius: 40px;
  --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners);
  background: #0e0e0f;
}

.verify-container {
  height: 100%;
}

.login_background {
  background-image: url("assets/auth/login_background.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.notch-div {
  background-color: #1f1f1f;
  width: 300px;
  border-radius: 10px;
}

.notch {
  width: 30px;
  height: 30px;
  background-color: #1f1f1f;
  rotate: 45deg;
  position: absolute;
  margin-bottom: -10px;
  border-radius: 10px;
}

.counter-ol {
  counter-reset: item;
}

.counter-li {
  display: block;
}

.counter-li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

.trash-icon {
  background-color: #2f2f2f !important;
}

.blog-top-section {
  background-color: #27292a;
  border-radius: 20px;
}

.blog-card {
  background-color: #27292a;
  border-radius: 20px;
}

.h-80vh {
  height: 80vh;
}

.h-90vh {
  height: 90vh;
}

.h-100vh {
  height: 100vh;
}

.h-200vh {
  height: 200vh;
}

.h-150vh {
  height: 150vh;
}

.h-125vh {
  height: 125vh;
}

.sitemap-link {
  color: #51c0ff;
}

.seller_section2_background {
  background-image: url("assets/seller/landing/91-is-better-background.webp");
  background-repeat: no-repeat;
  background-size: auto;
}

.seller_section4_background {
  background-image: url("assets/seller/landing/section-four-background.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.seller_section5_background {
  background-image: url("assets/seller/landing/section-five-background-mobile.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.seller_section6_background {
  background-image: url("assets/seller/landing/section-six-background.webp");
  background-repeat: no-repeat;
  background-size: contain;
}

.seller_section7_background {
  background-image: url("assets/seller/landing/section-seven-background.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.seller_section8_background {
  background-image: url("assets/seller/landing/section-eight-background.webp");
  background-repeat: no-repeat;
  background-size: auto;
}

// .bg-seller-section1 {
//   background-image: url("assets/auth/login_background.svg");
//   background-repeat: no-repeat;
//   background-size: cover;
// }

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

@media (max-width: 991.98px) {
  .custom-width-class {
    width: 100%;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .seller_section2_background {
    background-image: url("assets/seller/landing/91-is-better-background.webp");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .seller_section4_background {
    background-image: url("assets/seller/landing/section-four-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .seller_section5_background {
    background-image: url("assets/seller/landing/section-five-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .seller_section6_background {
    background-image: url("assets/seller/landing/section-six-background.webp");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .seller_section7_background {
    background-image: url("assets/seller/landing/section-seven-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .seller_section8_background {
    background-image: url("assets/seller/landing/section-eight-background.webp");
    background-repeat: no-repeat;
    background-size: auto;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 96px !important;
  }

  h2,
  .h2 {
    font-size: 64px !important;
  }

  .font-size48 {
    font-size: 48px !important;
    font-weight: 500;
  }

  h3 {
    font-size: 40px !important;
  }

  .font-size32 {
    font-size: 32px;
  }

  h4,
  .h4 {
    font-size: 24px !important;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.15px;
  }

  .body-text1 {
    font-family: "Poppins" !important;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  .body-text2 {
    font-family: "Poppins" !important;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
  }


  .caption {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
  }

  .overline {
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 1.5px;
  }

  .small-title {
    font-weight: 500;
    font-size: 10.5px;
  }

  .trendingsliderclass {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .verify-container {
    height: 600px;
  }

  .w-dialog {
    width: 1100px;
  }

  .seller_section2_background {
    background-image: url("assets/seller/landing/91-is-better-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .seller_section3_analytics_background {
    background-image: url("assets/seller/landing/analytics.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .seller_section4_background {
    background-image: url("assets/seller/landing/section-four-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .seller_section5_background {
    background-image: url("assets/seller/landing/section-five-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .seller_section6_background {
    background-image: url("assets/seller/landing/section-six-background.webp");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .seller_section7_background {
    background-image: url("assets/seller/landing/section-seven-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .seller_section8_background {
    background-image: url("assets/seller/landing/section-eight-background.webp");
    background-repeat: no-repeat;
    background-size: auto;
  }

  .vrsolutions-background-case {
    background-image: url("assets/images/home/now_on_meta_oculus_store.webp");
    background-repeat: no-repeat;
    background-position: 10% 1%;
    background-size: 70px;
    background-color: #1f1f1f;
  }

}

/* // Extra extral large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .w-95 {
    width: 90%;
  }

  .case-study-box {
    width: 100% !important;
  }
}

.custom-tooltip {
  background-color: #141415;
  color: #fff;
  font-size: 14px;
  border-radius: 16px;
  width: 424px;
  height: 80px;
  margin-top: 22px;
}

.custom-tooltip .tooltip-inner {
  background-color: transparent;

}


.draggable-images-background {
  background-color: #141415 !important;
}

.custom-slider-progress-bar .rs-slider-progress-bar {
  background: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%) !important;
}

.custom-slider-progress-bar .rs-slider-bar {
  background: #3D3D3D !important;

}

.custom-slider-progress-bar .rs-slider-handle::before {
  background-color: rgb(255, 255, 255) !important;
  border: 0px !important;
  height: 20px !important;
  width: 20px !important;
  outline: none;
  box-sizing: unset !important;
}

.custom-slider-progress-bar .rs-slider-handle:active:focus,
.custom-slider-progress-bar .rs-slider-handle:active::before,
.custom-slider-progress-bar .rs-slider-handle:focus::before,
.custom-slider-progress-bar .rs-slider-handle:focus,
.custom-slider-progress-bar .rs-slider-handle::after {
  background-color: rgb(255, 255, 255) !important;
  outline: none !important;
  box-shadow: none !important;
  box-sizing: unset !important;

}


/* styles for progress bar */
.custom-slider .slider-progress {
  background-color: #00FF00;
}

/* styles for slider bar */
.custom-slider .slider-bar {
  background-color: #CCCCCC;
}

/* styles for non-progress bar */
.custom-slider .slider-bar-2 {
  background-color: #FFFFFF;
}

/* styles for handle */
.custom-slider .slider-handle {
  background-color: #FF0000;
  border: 1px solid #000000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.custom-switch .react-switch-handle {
  height: 16px !important;
  width: 16px !important;
  top: 10px !important;
  left: 4px !important;
}

.custom-switch .react-switch-bg {
  height: 37px !important;
  width: 58px !important;
  border-radius: 22px !important;
 
}

.react-slider-tooltip {
  display: none;
}



.switch-field {
  display: flex;
  // margin-bottom: 36px;

  overflow: hidden;
  font-family: "Poppins";
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  background-color: #0D0D0D;
  color: #fff;
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #0D0D0D;
  color: #FFFFFF;
  font-size: 14px;
  // line-height: 1;
  text-align: center;
  padding: 8px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
  height: 40px;
  width: 140px;
  border-radius: 5px;

}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked+label {
  background-color: #fff;
  color: #000;
  box-shadow: none;
}

// .switch-field label:first-of-type {
// 	border-radius: 4px 0 0 4px;
// }

// .switch-field label:last-of-type {
// 	border-radius: 0 4px 4px 0;
// }

.required::after {
  content: " *";
  color: red;
}

.non-selectable {
  user-select: none;
}


.my-select:hover::after {
  transform: translateY(-50%) rotate(-135deg);
}

.my-select::-webkit-select-placeholder {
  /* For Chrome/Opera/Safari */
  color: #fff;
}

.my-select::-moz-placeholder {
  /* For Firefox 19+ */
  color: #fff;
}

.my-select:-ms-input-placeholder {
  /* For IE 10+ */
  color: #fff;
}

.my-select:-moz-placeholder {
  /* For Firefox 18- */
  color: #fff;
}

.my-select option {
  background-color: #0c0d0c;
  color: #fff;
}

.custom-form-control,
.custom-form-control:hover,
.custom-form-control:focus,
.custom-form-control:active {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.modal-open {
  overflow: hidden;
}

.modal-container {
  overflow-y: auto;
}

.custom-modal .custom-modal:modal-content {
  border: none;
  border-radius: none;
}

.custom-modal-dialog {
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
}

.my-select {
  -webkit-appearance: none;
  appearance: none;
  background-color: #141414;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5'%3E%3Cpath fill='%23ffffff' d='M4 5L0 1h8z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #3D3D3D;
  border-radius: 10px;
  padding: 0.5rem 2rem 0.5rem 1rem;
  color: white;
}

.my-select::-ms-expand {
  color: white;
  font-size: 10rem; 
  line-height: 10px;
  padding: 0; 
  margin-left: 5px

}

.my-select:focus{
  border-color: #3D3D3D !important;
}


.my-forms{
  border-color: #3D3D3D !important;
  border : 1px solid #3D3D3D !important;
  border-radius: 10px !important;
  background-color: #0D0D0D !important;
}
.my-forms:focus{
  border-color: #3D3D3D !important;
  border : 1px solid #3D3D3D !important;
  border-radius: 10px !important;
  background-color: #0D0D0D !important;
}

.my-forms:hover{
  border-color: #3D3D3D !important;
  border : 1px solid #3D3D3D !important;
  border-radius: 10px !important;
  background-color: #0D0D0D !important;
}
.custom-dots-list{
  height: 0px !important;
}
.slick-dots li{
  height: 8px !important;

}

.card-action-wrap{
  overflow-wrap: break-word;
  word-wrap: break-word;
}