.oopsZIndex {
  // position: absolute;
  // left: 400px;
  // top: 450px;
  margin-top: -100px;
  z-index: 1;
}

.progress-bar-container {
  background-color: #1f1f1f;
  border: 2px solid black;
}

.order-details-card {
  min-height: 400px;
  min-width: 200px;
  max-width: 600px;
  border-radius: 20px !important;
}

.order-card-action {
  min-height: 75px;
  min-width: 200px;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 20px !important;
  background-color: #2e2e2e;
}

.successful-card-action {
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 10px !important;
  background-color: #2e2e2e;
}

.successful-order-card {
  min-width: 300px !important;
  min-height: 300px !important;
  border-radius: 10px !important;
  background-color: #141415 !important;
  border: 1px solid #292929 !important;
}

.progress-bar-background {
  background-color: #1f1f1f;
  border: 1px solid black;
}

.in-progress-color {
  background-color: #828282;
}

.completed-color {
  background-color: #58d31b;
}

.text-inprogress {
  color: #828282;
}

.scroll-y {
  overflow-y: auto;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .successful-order-card {
    min-width: 600px !important;
    min-height: 300px !important;
    border-radius: 10px !important;
    background-color: #141415 !important;
    border: 1px solid #292929 !important;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .successful-order-card {
    min-width: 800px !important;
    min-height: 300px !important;
    border-radius: 10px !important;
    background-color: #141415 !important;
    border: 1px solid #292929 !important;
  }

  .successful-card-action {
    min-height: 75px;
    min-width: 200px;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 10px !important;
    background-color: #2e2e2e;
  }
}

/* // Extra extral large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .successful-order-card {
    min-width: 800px !important;
    min-height: 300px !important;
    border-radius: 10px !important;
    background-color: #141415 !important;
    border: 1px solid #292929 !important;
  }
}