.listItem {
  background: transparent !important;
  border: none !important;
  list-style-type: disc !important;
}

.itemGroup {
}

.admin-filedrop-card {
  min-height: 450px;
}

.productArchiveButton {
  left: -20px;
  top: -20px;
}
