.oopsZIndex {
  // position: absolute;
  // left: 400px;
  // top: 450px;
  margin-top: -100px;
  z-index: 1;
}

.progress-bar-background {
  background-color: #1f1f1f;
  border: 1px solid black;
}

.in-progress-color {
  background-color: #828282;
}

.completed-color {
  background-color: #58d31b;
}

.text-inprogress {
  color: #828282;
}

.scroll-y {
  overflow-y: auto;
}

.spring-in {
  background-color: green;
  color: white;
  // margin-top: -250px;
  // opacity: 0;
  // transition-property: margin-top, opacity;
  // transition-duration: 1s;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* // Extra extral large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {}