.home-background-first {
  background-color: #1f1f1f;
  background-image: url("../../assets/images/home/pizza-fries-coke-small.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.home-background-categories {
  background-color: #1f1f1f;
}

.home-background-carousel {
  background-color: #1f1f1f;
  margin: 0%;
}

.home-background-advertise {
  background-color: #1f1f1f;
}

.home-background-business {
  background-color: #1f1f1f;
}

.search-label {
  font-size: 40px;
}

.mobile-auth-background {
  background-image: url("../../assets/auth/mobile-auth-background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba($color: #1b1c1d, $alpha: 1);
}

.mobile-email-sent-background {
  background-color: #292929;
}

.mobile-error-background {
  background-image: url("../../assets/auth/mobile-auth-background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba($color: #1b1c1d, $alpha: 1);
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .home-background-first {
    background-image: url("../../assets/images/home/pizza-fries-coke.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    background-color: rgba($color: #1b1c1d, $alpha: 1);
  }

  .home-background-categories {
    background-color: #1f1f1f;
  }

  .search-label {
    font-size: 40px;
  }

  .home-background-carousel {
    background-image: url("../../assets/images/home/trending-vertical.webp");
    background-repeat: no-repeat;
    background-position: 70% -10%;
    background-color: rgba($color: #141415, $alpha: 1);
  }

  .error-background {
    background-image: url("../../assets/auth/login_background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba($color: #1b1c1d, $alpha: 1);
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // Extra extral large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
