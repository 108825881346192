  
  /* // Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
  }
  
  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }
  
  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }
  
  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .item-details-card {
      min-height: 175px;
      min-width: 550px;
      border-radius: 20px !important;
    }
  }
  
  /* // Extra extral large devices (large desktops, 1200px and up) */
  @media (min-width: 1400px) {
  }
  